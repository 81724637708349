<template>
	<div class="playback">
		<div class="playback-head">
			<div class="title">{{$t('liveplatform.livePlayback.pageTitle')}}</div>
		</div>
		<div class="playback-top">
			<div class="item">
				<div class="item-value">{{playbackData.view_count}}</div>
				<div class="item-label">{{$t('liveplatform.livePlayback.paneText1')}}</div>
			</div>
			<div class="item">
				<div class="item-value">{{playbackData.trans_count}}</div>
				<div class="item-label">{{$t('liveplatform.livePlayback.paneText2')}}</div>
			</div>
			<div class="item">
				<div class="item-value">{{playbackData.trans_amount}}</div>
				<div class="item-label">{{$t('liveplatform.livePlayback.paneText3')}}</div>
			</div>
			<div class="item">
				<div class="item-value">{{playbackData.max_online}}</div>
				<div class="item-label">{{$t('liveplatform.livePlayback.paneText4')}}</div>
			</div>
			<div class="item">
				<div class="item-value">{{playbackData.view_dura}}</div>
				<div class="item-label">{{$t('liveplatform.livePlayback.paneText5')}}</div>
			</div>
			<div class="item">
				<div class="item-value">{{playbackData.new_fans}}</div>
				<div class="item-label">{{$t('liveplatform.livePlayback.paneText6')}}</div>
			</div>
		</div>
		<div class="playback-wrap">
			<div class="playback-preview">
				<div class="preview-top">
					<div class="tip">{{$t('liveplatform.livePlayback.tips1')}}</div>
				</div>
				<div class="preview-main">
					<div id="player-con" class="preview-player">
						<div class="preview-status" v-if="playbackInfo.aliyun_status!==2">{{$t('liveplatform.livePlayback.noPicture')}}</div>
					</div>
				</div>
				<!-- <div class="preview-bottom">
					<el-button round>{{$t('liveplatform.livePlayback.explainBtn')}}</el-button>
					<el-button round>{{$t('liveplatform.livePlayback.largeDataBtn')}}</el-button>
				</div> -->
			</div>
			<div class="playback-echarts">
				<div class="echarts-top"></div>
				<div id="myChart" style="height: 522px;"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import { fetchPlayback, fetchPlaybackStatistics} from '@/api/liveplatform';
	export default {
		data() {
			return {
				playbackData: {
					view_count: '0',
					trans_count: '0',
					trans_amount: '0.00',
					max_online: '0',
					view_dura: '0',
					new_fans: '0',
				},
				playbackInfo: {},
				aliPlayer: null,
				playbackList: [
					["2022-02-05 12:05:00", 116],
					["2022-02-05 12:10:00", 129],
					["2022-02-05 12:15:00", 135],
					["2022-02-05 12:20:00", 86],
					["2022-02-05 12:25:00", 73],
					["2022-02-05 12:30:00", 85],
					["2022-02-05 12:35:00", 73],
					["2022-02-05 12:40:00", 68],
					["2022-02-05 12:45:00", 92],
					["2022-02-05 12:50:00", 130],
					["2022-02-05 12:55:00", 245],
					["2022-02-05 13:00:00", 139],
					["2022-02-05 13:05:00", 115],
					["2022-02-05 13:10:00", 111],
					["2022-02-05 13:15:00", 309],
					["2022-02-05 13:20:00", 206],
					["2022-02-05 13:20:00", 137],
					["2022-02-05 13:25:00", 128],
					["2022-02-05 13:30:00", 85],
					["2022-02-05 13:35:00", 94],
					["2022-02-05 13:40:00", 71],
					["2022-02-05 13:45:00", 106],
					["2022-02-05 13:50:00", 84],
					["2022-02-05 13:55:00", 93],
					["2022-02-05 14:00:00", 85],
					["2022-02-05 14:05:00", 73],
					["2022-02-05 14:10:00", 83],
					["2022-02-05 14:15:00", 125],
					["2022-02-05 14:20:00", 107],
					["2022-02-05 14:25:00", 82],
					["2022-02-05 14:30:00", 44]
				]
			}
		},
		mounted() {
			this.getPlaybackData();
			this.getPlaybackInfo();
			// this.initEcharts();
		},
		methods: {
			getPlaybackData(){
				let param = {
					live_id: this.$route.params.id,
					app_key: this.$store.getters.appKey
				}
				fetchPlaybackStatistics(param).then(response => {
					this.playbackData = response.data;
				})
			},
			getPlaybackInfo(){
				let param = {
					live_id: this.$route.params.id,
					app_key: this.$store.getters.appKey
				}
				fetchPlayback(param).then(response => {
					this.playbackInfo = response.data;
					if(response.data.aliyun_status==2 && response.data.play_back_url_https){
						this.createAliplayer(response.data.play_back_url_https,'');
					}
				}).catch(() => {
					this.$router.push({name: 'LiveList'});
				})
			},
			createAliplayer(source, cover) {
				let props = {
					"id": "player-con",
					"source": source,
					// "cover": cover,
					"width": "100%",
					"height": "100%",
					"autoplay": false,
					"isLive": true,
					"rePlay": false,
					"playsinline": true,
					"preload": true,
					"controlBarVisibility": "always",
					"useH5Prism": true
				}
				this.aliPlayer = window.Aliplayer(props);
				let that = this;
				this.aliPlayer.on('error',function(e){
					that.playbackInfo.aliyun_status = 3;
					document.querySelector('.prism-ErrorMessage').setAttribute('style','display:none');
				})
			},
			initEcharts() {
				const dateList = this.playbackList.map(function(item) {
					return item[0];
				});
				const valueList = this.playbackList.map(function(item) {
					return item[1];
				});
				var myChart = echarts.init(document.getElementById('myChart'));
				// // 绘制图表
				myChart.setOption({
					visualMap: {
						show: false,
						type: 'continuous',
					},
					title: {
						text: this.$t('liveplatform.liveInfo.chartTitle'),
						textStyle: {
							color: "#ffffff",
							fontWeight: "normal",
							fontSize: "14"
						}
					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						// type: 'time',
						data: dateList,
					},
					yAxis: {
						splitLine: {
							show: false
						},
						axisLine: {
							show: true
						}
					},
					series: [{
						type: 'line',
						showSymbol: false,
						smooth: true,
						data: valueList
					}]
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.playback {
		padding: 20px;
		border-radius: 6px;
		min-height: 400px;
		background-color: $--live-background-default;
		.playback-head {
			margin-bottom: 20px;
			.title {
				color: #FFFFFF;
				font-size: 15px;
				line-height: 25px;
				height: 25px;
			}
		}
		.playback-top {
			background-color: $--live-gray-white-6;
			border-radius: 6px;
			padding: 22px 30px;
			display: flex;
			justify-content: space-evenly;
			.item  {
				margin: 0 10px;
				.item-value {
					color: #FFFFFF;
					font-size: 25px;
					line-height: 1em;
					text-align: center;
				}
				.item-label {
					font-size: 12px;
					color: #FFFFFF;
					opacity: 0.6;
					margin-top: 10px;
					line-height: 1em;
				}
			}
		}
		.playback-wrap {
			display: flex;
			.playback-preview {
				width: 290px;
				padding: 10px 20px 10px 10px;
				margin-right: 10px;
				.preview-top {
					margin-bottom: 8px;
					.tip {
						font-size: 12px;
						line-height: 20px;
						color: rgba(255,255,255,.6);
						text-align: center;
						margin-bottom: 2px;
					}
				}
				.preview-main {
					height: 516px;
					border-radius: 10px;
					overflow: hidden;
					background-color: #000000;
					.preview-player {
						width: 100%;
						height: 100%;
						position: relative;
						.preview-status {
							top: 50%;
							left: 50%;
							color: #FFFFFF;
							transform: translate3d(-50%,-50%,0);
							position: absolute;
						}
					}
				}
				.preview-bottom {
					display: flex;
					margin-top: 24px;
					justify-content: space-between;
					::v-deep .el-button {
						color: #FFFFFF;
						font-size: 18px;
						padding: 13px 27px;
						border-radius: 23px;
						border-color: rgba(255,255,255,.2);
						background-color: rgba(255,255,255,.2);
					}
				}
			}
			.playback-echarts {
				flex: 1;
				.echarts-top {
					height: 50px;
					margin-top: 5px;
				}
			}
		}
	}
</style>
